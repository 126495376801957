import * as React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import {
  CssBaseline, AppBar, Toolbar, IconButton, Typography, Hidden, Drawer, List, Divider,
  createStyles, Theme, withStyles, WithStyles,
  Backdrop, CircularProgress
} from '@material-ui/core';
import { IRouteItem, StandardRouteItem } from './routes'
import MenuIcon from '@material-ui/icons/Menu';

const drawerWidth = 240;

export const appStyles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexGrow: 1
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
});

type AppStyleProps = WithStyles<typeof appStyles>;

function baseAppRouter(props: AppStyleProps) {

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const routeItems: IRouteItem[] = [
    new StandardRouteItem('Home', 'home', () => <div>Home</div>)
  ]

  const getDrawer = () => {
    return (
      <div>
        <div
          className={props.classes.toolbar}
        />
        <Divider />
        <List>
          {routeItems.map(item => item.getNavBarRender())}
        </List>
      </div>
    )
  }

  return (

    <React.Fragment>
      <BrowserRouter>
        <div
          className={props.classes.root}
        >
          <CssBaseline />
          <AppBar position="fixed"
            className={props.classes.appBar}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                edge="start"
                onClick={() => { setDrawerOpen(!drawerOpen) }}
                className={props.classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap>
                Dungeon Realms
            </Typography>
            </Toolbar>
          </AppBar>
          <nav
            className={props.classes.drawer}
            aria-label="Mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
              <Drawer
                variant="temporary"
                anchor="left"
                open={drawerOpen}
                onClose={() => { setDrawerOpen(!drawerOpen) }}
                classes={{
                  paper: props.classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                onClick={() => { setDrawerOpen(!drawerOpen) }}
              >
                {getDrawer()}
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: props.classes.drawerPaper
                }}
                variant="permanent"
                open
              >
                {getDrawer()}
              </Drawer>
            </Hidden>
          </nav>

          <main>
            <div
              className={props.classes.toolbar}
            />
            <Switch >
              {
                routeItems.map(item => item.getSwitchRender())
              }
              {
                <Route exact path="*" render={() => <Redirect to={`/${(routeItems[0] as StandardRouteItem).routePath}`} />} />
              }
            </Switch>
          </main>
        </div >
      </BrowserRouter>
      <Backdrop
         className={props.classes.backdrop}
        // open={props.globalState.uiLoading}
        open={false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  )
}

export default withStyles(appStyles)(baseAppRouter)