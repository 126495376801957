import { IRouteItem } from ".";
import * as React from "react";
import { Route } from "react-router";
import { ListItem, ListItemText } from "@material-ui/core";
import { NavLink, LinkProps } from "react-router-dom";

export class StandardRouteItem implements IRouteItem {

  constructor(
    public readonly routeLabel: string,
    public readonly routePath: string,
    public readonly component: React.ComponentType
  ) { }


  getNavBarRender(prefix?: string): JSX.Element | null {
    let to = `${prefix || ''}/${this.routePath}`;
    return (
      <ListItem button key={this.routeLabel}
        component={React.forwardRef<HTMLAnchorElement, Partial<LinkProps>>((props, ref) =>
          (<NavLink to={to} {...props} innerRef={ref}>
          </NavLink>))} >
        <ListItemText primary={this.routeLabel} />
      </ListItem>
    )
  }

  getSwitchRender(prefix?: string): JSX.Element | null {
    let path = `${prefix ? prefix : ''}/${this.routePath}`
    return <Route path={path} component={this.component} key={this.routeLabel} />
  }


}